import React, { useState } from 'react';
import useFetchData from './hooks/useFetchData';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import 'chartjs-plugin-crosshair';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/Dashboard/NavBar';
import { theme } from './themes/theme';
import DashboardSummary from './components/Dashboard/DashboardSummary';
import DashboardStockPortfolioTable from './components/Dashboard/DashboardStockTable';
import DashboardStockChart from './components/Dashboard/DashboardStockChart';
import DashboardStockPortfolioChart from './components/Dashboard/DashboardStockPortfolioChart.js';
import DashboardSectorPie from './components/Dashboard/DashboardSectorPie';
import DashboardStockDividend from './components/Dashboard/DashboardStockDividend';
import useStockManagement from './hooks/useStockManagement';
import CircularProgress from '@mui/material/CircularProgress';
import useUserInfo from './hooks/useUserInfo';
import { Box, Button } from '@mui/material';
import { Lock } from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
// import Container from '@mui/material/Container';
// import containerStyle from './themes/theme';
import Footer from './Footer.js';
import { Typography } from '@mui/material';

function ProtectedPage() {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const data = useFetchData(`${apiUrl}/stocks/holdings`);
  const stock_portfolio_data = useFetchData(`${apiUrl}/stocks/total_historic_investment_tracker`);
  const userInfo = useUserInfo(apiUrl);
  console.log('userInfo', userInfo.email);
  const {
    showPopup,
    formValues,
    timeRange,
    showDeletePopup,
    selectedStocks,
    handleAddStock,
    handleClosePopup,
    handleInputChange,
    handleSubmit,
    handleOpenDeletePopup,
    handleCloseDeletePopup,
    handleConfirmDelete,
    handleStockSelectionChange,
    filteredData,
    filteredChartData,
    setTimeRange
  } = useStockManagement(apiUrl, data);

  if (userInfo.is_authenticated === false) {
    window.location.href = "/signin";
    return null;
  }

  const handleSubscribeClick = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await fetch(`${apiUrl}/send_interest`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        setSuccessMessage('Subscription request sent successfully. Someone from our team will reach out to you soon.'); 
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      } else {
        setError('An error occurred while sending the subscription request.');
        setTimeout(() => {
          setError('');
        }, 3000);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while sending the subscription request.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      {userInfo.email !== "" && userInfo.is_subscribed ? (
        data && stock_portfolio_data ? (
          <div>
            {/* <Container>
            <h1 container style={{padding: '', marginBottom: 0}}>Stock Portfolio</h1>
            </Container> */}
            <Grid container spacing={2} 
              style={{ 
              display: 'flex',
              justifyContent: 'center',
              }}>
              <Grid item xs={6} md={6}>
                <DashboardSummary data={data} />
                <DashboardStockPortfolioTable
                  data={data}
                  filteredData={filteredData}
                  showPopup={showPopup}
                  formValues={formValues}
                  handleAddStock={handleAddStock}
                  handleClosePopup={handleClosePopup}
                  handleInputChange={handleInputChange}
                  handleSubmit={handleSubmit}
                  showDeletePopup={showDeletePopup}
                  handleOpenDeletePopup={handleOpenDeletePopup}
                  handleCloseDeletePopup={handleCloseDeletePopup}
                  handleConfirmDelete={handleConfirmDelete}
                />
                <DashboardSectorPie data={data} />
              </Grid>
              <Grid item xs={6} md={6}>
                <DashboardStockDividend data={data} />
                <DashboardStockPortfolioChart
                  data={stock_portfolio_data}
                />
                <DashboardStockChart 
                  data={data} 
                  filteredChartData={filteredChartData} 
                  timeRange={timeRange} 
                  setTimeRange={setTimeRange} 
                  selectedStocks={selectedStocks} 
                  handleStockSelectionChange={handleStockSelectionChange} 
                />
              </Grid>
            </Grid>
            <Footer />
          </div>
          
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
            <CircularProgress />
            <p>Loading data...</p>
          </div>
        )
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
          flexDirection="column"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            padding={10}
            sx={{ marginTop: '24px', 
              padding: '100px', 
              borderRadius: '0px', 
              // border: '0.5px solid #000',
              // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
              marginBottom: '20px',
              maxWidth: '500px',
              textAlign: 'center'}}
          >
            <Lock style={{ fontSize: 80, marginBottom: 0, opacity: '60%' }} />
            <p>Click the button below to send a request and someone from our team will reach out to you to set up your subscription.</p>
            <Button
              variant="contained"
              color="primary"
              size='large'
              onClick={handleSubscribeClick}
              style={{ textTransform: 'none', marginBottom: 20, borderRadius: 0 }}
            >
              I'm ready to subscribe
            </Button>
            {successMessage && (
              <p style={{ color: 'green' }}>{successMessage}</p>
            )}
            {error && (
              <p style={{ color: 'red' }}>{error}</p>
            )}
          </Box>
          <Typography variant="body1" color="textSecondary" sx={{textAlign:'center', marginBottom: '0px'}}>
              &copy; {new Date().getFullYear()} Capanu. All rights reserved.
          </Typography>
        </Box>
      )}
    </ThemeProvider>
  );
}

export default ProtectedPage;

import React from 'react';
// import useFetchData from './hooks/useFetchData';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import 'chartjs-plugin-crosshair';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/Dashboard/NavBar';
import { theme } from './themes/theme';
import useUserInfo from './hooks/useUserInfo';
import { Box, Button } from '@mui/material';
import { Lock } from '@mui/icons-material';
import { Typography } from '@mui/material';

function ProtectedPage() {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const userInfo = useUserInfo(apiUrl);
  console.log('userInfo', userInfo.email);

  if (userInfo.is_authenticated === false) {
    window.location.href = "/signin";
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      {userInfo.email !== "" && userInfo.is_subscribed ? (
          <div>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50vh"
              flexDirection="column"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                padding={10}
                sx={{ marginTop: '-16px', 
                  padding: '100px', 
                  borderRadius: '0px', 
                  // border: '0.5px solid #000',
                  // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
                  marginBottom: '20px' }}
              >
                <h1>Coming Soon</h1>
                <p>We are working hard to bring you this feature. Stay tuned!</p>
                <Button
                  variant="contained"
                  color="primary"
                  size='large'
                  onClick={() => window.location.href = '/dashboard'}
                  style={{ textTransform: 'none', marginBottom: 20, borderRadius: 0 }}
                >
                  Dashboard
                </Button>
              </Box>
            </Box>
            <Typography variant="body1" color="textSecondary" sx={{textAlign:'center', marginBottom: '0px'}}>
                &copy; {new Date().getFullYear()} Capanu. All rights reserved.
            </Typography>
          </div>
        ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
          flexDirection="column"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            padding={10}
            sx={{ marginTop: '24px', 
              padding: '100px', 
              borderRadius: '0px', 
              // border: '0.5px solid #000',
              // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
              marginBottom: '20px',
              maxWidth: '500px',
              textAlign: 'center'}}
          >
            <Lock style={{ fontSize: 80, marginBottom: 0, opacity: '60%' }} />
            <p>Click the button below to send the a request and someone from our team will reach out to you to set up your subscription.</p>
            <Button
              variant="contained"
              color="primary"
              size='large'
              onClick={() => window.location.href = '/#pricing'}
              style={{ textTransform: 'none', marginBottom: 20, borderRadius: 0 }}
            >
              I'm ready to subscribe
            </Button>
          </Box>
          <Typography variant="body1" color="textSecondary" sx={{textAlign:'center', marginBottom: '0px'}}>
              &copy; {new Date().getFullYear()} Capanu. All rights reserved.
          </Typography>
        </Box>
      )}
    </ThemeProvider>
  );
}

export default ProtectedPage;

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useState } from 'react';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function NavBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/signin';
    handleClose();
  };

  const renderMenu = (
    <Menu
      sx={{ marginTop: '45px', marginLeft: '-10px' }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {/* <MenuItem onClick={handleClose}>Account</MenuItem> */}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      sx={{ marginTop: '45px', marginLeft: '-10px' }}
      id="menu-mobile"
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose} href="/dashboard">Dashboard</MenuItem>
      <MenuItem onClick={handleClose} href="/stocks">Stocks</MenuItem>
      <MenuItem onClick={handleClose} href="/bonds">Bonds</MenuItem>
      <MenuItem onClick={handleClose} href="/mutualfunds">Mutual Funds</MenuItem>
      <MenuItem onClick={handleClose} href="/etfs">ETFs</MenuItem>
      {/* <MenuItem onClick={handleClose}>Account</MenuItem> */}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '0px' }}>
      <AppBar 
        position="static" 
        // elevation={3} 
        sx={{ 
          borderRadius: '0', 
          width: '100%', 
          // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          borderBottom: '0.5px solid #000',
          backgroundColor: 'white',
          padding: '5px 0px 3px'
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <a href="/dashboard" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
              <img src={`${process.env.PUBLIC_URL}/logo-green.png`} alt="Logo" style={{ height: '35px', marginRight: '10px' }} />
              <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', color: '#333', paddingBottom: '3px' }}>
                capanu
              </Typography>
            </a>
          </Box>
          {isMobile ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="menu"
                aria-controls="menu-mobile"
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                sx={{ color: '#333', fontSize: '2rem' }} 
              >
                <MenuIcon />
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button color="inherit" href="/dashboard" sx={{ marginRight: '10px', textTransform: 'none' }}>Dashboard</Button>
              <Button color="inherit" href="/stocks" sx={{ marginRight: '10px', textTransform: 'none' }}>Stocks</Button>
              <Button color="inherit" href="/bonds" sx={{ marginRight: '10px', textTransform: 'none' }}>Bonds</Button>
              <Button color="inherit" href="/mutualfunds" sx={{ marginRight: '10px', textTransform: 'none' }}>Mutual Funds</Button>
              <Button color="inherit" href="/etfs" sx={{ marginRight: '10px', textTransform: 'none' }}>ETFs</Button>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                sx={{ color: '#333', fontSize: '2rem' }} 
              >
                <AccountCircle fontSize="inherit" />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMobileMenu}
    </Box>
  );
}

export default NavBar;
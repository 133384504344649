import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function NavBar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '0', borderRadius: 0 }}>
            <AppBar 
                position="static" 
                // elevation={3} 
                sx={{ 
                    borderRadius: '0', 
                    width: '100%', 
                    // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'white', 
                    borderBottom: '0.5px solid #000',
                    padding: '5px 0px 3px'
                }}
            >
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <a href="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            <img src={`${process.env.PUBLIC_URL}/logo-green.png`} alt="Logo" style={{ height: '35px', marginRight: '10px' }} />
                            <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', color: '#333', paddingBottom: '3px' }}>
                                capanu
                            </Typography>
                        </a>
                    </Box>
                    {isMobile ? (
                        <>
                            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                sx={{ marginTop: '0px', marginLeft: '-25px', border: '0.5px solid #000' }}
                            >
                                <MenuItem onClick={handleMenuClose} component="a" href="#about">About</MenuItem>
                                <MenuItem onClick={handleMenuClose} component="a" href="#features">Features</MenuItem>
                                <MenuItem onClick={handleMenuClose} component="a" href="#how-it-works">How it works</MenuItem>
                                <MenuItem onClick={handleMenuClose} component="a" href="#reviews">Reviews</MenuItem>
                                <MenuItem onClick={handleMenuClose} component="a" href="#pricing">Pricing</MenuItem>
                                <MenuItem onClick={handleMenuClose} component="a" href="/signin">Login</MenuItem>
                                <MenuItem onClick={handleMenuClose} component="a" href="/signup">Create Account</MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button color="inherit" href="#about" sx={{ marginRight: '10px', textTransform: 'none' }}>About</Button>
                            <Button color="inherit" href="#features" sx={{ marginRight: '10px', textTransform: 'none' }}>Features</Button>
                            <Button color="inherit" href="#how-it-works" sx={{ marginRight: '10px', textTransform: 'none' }}>How it works</Button>
                            <Button color="inherit" href="#reviews" sx={{ marginRight: '10px', textTransform: 'none' }}>Reviews</Button>
                            <Button color="inherit" href="#pricing" sx={{ marginRight: '10px', textTransform: 'none' }}>Pricing</Button>
                            <Button variant="outlined" color="primary" href="/signin" sx={{ marginRight: '10px', textTransform: 'none', borderRadius: 0 }}>Login</Button>
                            <Button variant="contained" color="primary" href="/signup" sx={{ marginRight: '10px', textTransform: 'none', borderRadius: 0 }}>Create Account</Button>
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default NavBar;
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Table, TableRow, TableCell, Button } from '@mui/material';
import { subDays, subMonths, startOfYear } from 'date-fns';

const DashboardStockPortfolio = ({ data }) => {
  const [timeRange, setTimeRange] = useState('ALL');

  if (!data || data.length === 0) return null;

  const filterDataByRange = (range) => {
    const now = new Date();
    switch (range) {
      case '1W':
        return data.filter(item => new Date(item.date) > subDays(now, 7));
      case '1M':
        return data.filter(item => new Date(item.date) > subMonths(now, 1));
      case '3M':
        return data.filter(item => new Date(item.date) > subMonths(now, 3));
      case 'YTD':
        return data.filter(item => new Date(item.date) > startOfYear(now));
      case '1Y':
        return data.filter(item => new Date(item.date) > subDays(now, 365));
      case 'ALL':
      default:
        return data;
    }
  };

  const filteredData = filterDataByRange(timeRange);
  const labels = filteredData.map(item => item.date);
  const colors = ['rgba(75,192,192,1)'];

  return (
    <div>
      <Line
        data={{
          labels,
          datasets: [
            {
              label: 'Total Stock Investments',
              data: filteredData.map(item => item.current_investment),
              fill: true,
              borderColor: colors[0],
              backgroundColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                  // This case happens on initial chart render
                  return null;
                }

                const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                gradient.addColorStop(0, 'rgba(75,192,192,0.1)');
                gradient.addColorStop(1, 'rgba(75,192,192,0.8)');
                return gradient;
                },
                tension: 0.1,
                pointRadius: 0,
                pointHoverRadius: 6,
                pointHoverBackgroundColor: colors[0]
              }
              ]
            }}
            options={{
              responsive: true,
              scales: {
              x: {
                title: {
                display: false
                },
                type: 'time',
                time: {
                unit: 'day',
                tooltipFormat: 'MMM dd, yyyy',
                displayFormats: { day: 'MMM dd, yyyy' }
                },
                ticks: { maxTicksLimit: 6, font: { family: 'Figtree' } },
                grid: { display: false },
                font: {
                family: 'Figtree'
                }
              },
              y: {
                display: false,
              }
              },
              plugins: {
              tooltip: {
                mode: 'index',
                intersect: false,
                bodyFont: {
                family: 'Figtree'
                },
                callbacks: {
                label: function (context) {
                  const entry = filteredData.find(e => new Date(e.date).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }) === context.label);
                  return `Total Investment: ${entry ? entry.current_investment.toFixed(2) : 'N/A'}`;
                }
              }
            },
            datalabels: {
              display: false
            },
            legend: {
              labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                padding: 20,
                boxWidth: 10,
                boxHeight: 10,
                font: {
                  family: 'Figtree'
                },
                generateLabels: (chart) => {
                  const data = chart.data;
                  return data.datasets.map((dataset, i) => ({
                    text: dataset.label,
                    fillStyle: colors[i % colors.length],
                    hidden: !chart.isDatasetVisible(i),
                    lineCap: dataset.borderCapStyle,
                    lineDash: dataset.borderDash,
                    lineDashOffset: dataset.borderDashOffset,
                    lineJoin: dataset.borderJoinStyle,
                    strokeStyle: dataset.borderColor,
                    pointStyle: 'circle',
                    datasetIndex: i
                  }));
                }
              },
              align: 'start'
            }
          },
          interaction: { mode: 'index', intersect: false },
          hover: { mode: 'index', intersect: false },
          font: {
            family: 'Figtree'
          }
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'left', marginTop: '20px' }}>
        {['1W', '1M', '3M', 'YTD', '1Y', 'ALL'].map(range => (
          <Button
            key={range}
            color="primary"
            size='small'
            onClick={() => setTimeRange(range)}
            style={{ margin: '0 0px', borderRadius: '0px' }}
            variant={timeRange === range ? 'outlined' : 'text'}
          >
            {range}
          </Button>
        ))}
      </div>
    </div>
  );
};

const DashboardStockPortfolioChart = ({ data }) => {
  return (
    <Box sx={{ 
      marginTop: '20px', 
      padding: '20px', 
      borderRadius: '0px',
      border: '0.5px solid #000', 
      // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
      marginBottom: '20px' 
      }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2>Portfolio Performance</h2>
            </div>
        <div>
        {data && (
          <DashboardStockPortfolio data={data} />
        )}
        {data && data.length === 0 && (
          <Table>
            <TableRow>
              <TableCell colSpan={8} style={{ textAlign: 'center', padding: '30px', fontSize: '42px', opacity: "50%", color: "rgba(0, 0, 0, 0.87)" }}>No data</TableCell>
            </TableRow>
          </Table>
        )}
      </div>
    </Box>
  );
};

export default DashboardStockPortfolioChart;

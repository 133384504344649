import React from 'react';
import { Box } from '@mui/material';
// import Grid from '@mui/material/Grid2';

const DashboardSummary = ({ data }) => {
    if (!Array.isArray(data)) {
        return null;
    }

    const totalInvestment = data.reduce((acc, row) => acc + (row.units * row.bought_price), 0);
    const currentValue = data.reduce((acc, row) => acc + (row.units * row.current_price), 0);
    const changePercentage = ((currentValue - totalInvestment) / totalInvestment) * 100;
    const dailyChangePercentage = data.map(stock => {
        const history = stock.stock_history;
        if (history.length < 2) return 0;

        const lastTwoDays = history.slice(-2);
        const [previousDay, lastDay] = lastTwoDays;
        const previousDayValue = previousDay.price * stock.units;
        const lastDayValue = lastDay.price * stock.units;

        return ((lastDayValue - previousDayValue) / previousDayValue) * 100;
    }).reduce((acc, change) => acc + change, 0) / data.length;
    const dailyChangeValue = data.map(stock => {
        const history = stock.stock_history;
        if (history.length < 2) return 0;

        const lastTwoDays = history.slice(-2);
        const [previousDay, lastDay] = lastTwoDays;
        const previousDayValue = previousDay.price * stock.units;
        const lastDayValue = lastDay.price * stock.units;

        return lastDayValue - previousDayValue;
    }).reduce((acc, change) => acc + change, 0) / data.length;

    const formatNumber = (num) => {
        if (num >= 1e9) {
            return (num / 1e9).toFixed(1) + 'B';
        } else if (num >= 1e6) {
            return (num / 1e6).toFixed(1) + 'M';
        } else if (num >= 1e3) {
            return (num / 1e3).toFixed(1) + 'K';
        } else {
            return num.toFixed(2);
        }
    };

    return (
        <Box sx={{ 
            marginTop: '20px', 
            padding: '30px 20px 30px', 
            borderRadius: '0', 
            // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
            border: '0.5px solid #000',
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            }}>
            <Box sx={{ flex: 1, marginRight: '10px' }}>
                <h2 style={{padding: '', marginBottom: '10px', marginTop: 0}}>Stock Portfolio</h2>
                    <h1 style={{ fontSize: '52px', fontWeight: 'bold', margin: 0, marginBottom: '10px' }}>
                        {formatNumber(currentValue)}
                    </h1>
                    <p  style={{ color: currentValue - totalInvestment >= 0 ? 'green' : 'red', margin: 0, marginBottom: '10px' }}>
                        {currentValue - totalInvestment >= 0 ? '+' : '-'}{Math.abs(currentValue - totalInvestment).toFixed(2)} ({changePercentage.toFixed(2)}%) <span style={{ color: '#000'}}>Total Returns</span>
                    </p>
                    <p  style={{ color: dailyChangeValue >= 0 ? 'green' : 'red', margin: 0 }}>
                        {dailyChangeValue >= 0 ? '+' : '-'}{Math.abs(dailyChangeValue).toFixed(2)} ({dailyChangePercentage.toFixed(2)}%) <span style={{ color: '#000'}}>Daily Returns</span>
                    </p>
            </Box>
            <Box sx={{ flex: 1, marginLeft: '10px', textAlign: 'right', marginTop: '15px' }}>
                <h3>Top weekly performers</h3>
                {data
                    .map(stock => {
                        const history = stock.stock_history;
                        if (history.length < 2) return { name: stock.name, dailyReturn: 0 };

                        const lastTwoDays = history.slice(-8);
                        const [previousDay, lastDay] = lastTwoDays;
                        const previousDayValue = previousDay.price;
                        const lastDayValue = lastDay.price;

                        const dailyReturn = ((lastDayValue - previousDayValue) / previousDayValue) * 100;
                        return { name: stock.ticker, dailyReturn };
                    })
                .sort((a, b) => b.dailyReturn - a.dailyReturn)
                .slice(0, 3)
                .map((stock, index) => (
                    <div key={index} style={{marginBottom: '10px'}}>
                        {stock.name}: <span style={{color: stock.dailyReturn >= 0 ? 'green' : 'red'}}>{stock.dailyReturn.toFixed(2)}%</span>
                    </div>
                ))}
            </Box>
        </Box>
    );
};

export default DashboardSummary;
import { createTheme } from "@mui/material";

export const theme = createTheme({
  shadows: Array(25).fill("none"),
  palette: {
    primary: {
      main: '#1db954',
      contrastText: '#000000',
    },
  },
  components: {
    MuiGrid2: {
      defaultProps: {
        disableEqualOverflow: true,
      },
    },
  },
  typography: {
    fontFamily: "'Figtree', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: 14,
    h1: {
      fontFamily: "'Figtree', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: '2rem',
    },
    h2: {
      fontFamily: "'Figtree', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: '1.75rem',
    },
  },
});

const containerStyle = {
  margin: '0 auto',
  padding: '0 20px'
};

export default containerStyle;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const DashboardAddStockDialog = ({ open, onClose, formValues, onInputChange, onSubmit }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Add New Stock</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        name="ticker"
        label="Ticker"
        type="text"
        fullWidth
        value={formValues.ticker}
        onChange={onInputChange}
      />
      <TextField
        margin="dense"
        name="units"
        label="Units"
        type="number"
        fullWidth
        value={formValues.units}
        onChange={onInputChange}
      />
      <TextField
        margin="dense"
        name="bought_price"
        label="Bought Price"
        type="number"
        fullWidth
        value={formValues.bought_price}
        onChange={onInputChange}
      />
      <TextField
        margin="dense"
        name="bought_at"
        label="Bought At"
        type="date"
        fullWidth
        slotProps={{
          inputLabel: {
            shrink: true,
          },
        }}
        value={formValues.bought_at}
        onChange={onInputChange}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">Close</Button>
      <Button onClick={onSubmit} color="primary">Submit</Button>
    </DialogActions>
  </Dialog>
);

export default DashboardAddStockDialog;
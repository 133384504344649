import React from 'react';
import { Toolbar, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Toolbar sx={{ textAlign: 'center', marginTop: '20px', justifyContent: 'center', bottom: 0, borderTop: '1px solid #e0e0e0' }}>
            <Typography variant="body1" color="textSecondary">
                &copy; {new Date().getFullYear()} Capanu. All rights reserved.
            </Typography>
        </Toolbar>
    );
};

export default Footer;
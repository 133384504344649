import React from 'react';
import { TextField, MenuItem } from '@mui/material';

const DashboardStockSelection = ({ data, selectedStocks, handleStockSelectionChange }) => (
  <TextField
    select
    label="Select Stocks"
    value={selectedStocks}
    onChange={handleStockSelectionChange}
    SelectProps={{
      multiple: true,
      renderValue: (selected) => selected.join(', '),
    }}
    fullWidth
    margin="normal"
    size='small'
    variant='standard'
  >
    {data.map((stock) => (
      <MenuItem key={stock.ticker} value={stock.ticker}>
        {stock.ticker}
      </MenuItem>
    ))}
  </TextField>
);

export default DashboardStockSelection;
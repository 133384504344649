import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signin from './Signin';
import Signup from './Signup';
import Dashboard from './Dashboard';
import Landing from './Landing';
import Stocks from './Stocks';
import Bonds from './Bonds';
import MutualFunds from './MutualFunds';
import ETFs from './ETFs';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './fonts.css';
function ScrollToSection() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#pricing') {
      const element = document.getElementById('pricing');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToSection />
      <Routes>
        <Route path="" element={<Landing />} />
        <Route path="signin" element={<Signin />} />
        <Route path="signup" element={<Signup />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="stocks" element={<Stocks />} />
        <Route path="bonds" element={<Bonds />} />
        <Route path="mutualfunds" element={<MutualFunds />} />
        <Route path="etfs" element={<ETFs />} />
      </Routes>
    </Router>
  );
}

export default App;
// No additional code needed at $PLACEHOLDER$